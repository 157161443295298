
import settings from '@/plugins/settings';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class BaseModal extends Vue {
  @Prop(Boolean) readonly value: boolean | undefined;
  @Prop(String) readonly width: string | undefined;
  @Prop(String) readonly height: string | undefined;
  @Prop(Boolean) readonly persistent: boolean | undefined;
  @Prop(Boolean) readonly hideOverlay: boolean | undefined;
  @Prop(Boolean) readonly flat: boolean | undefined;
  @Prop(Boolean) readonly noClose: boolean | undefined;
  @Prop(Boolean) readonly noFull: boolean | undefined;
  @Prop(Boolean) readonly noScroll: boolean | undefined;
  @Prop() readonly backClose: any | undefined;

  @Watch('$route.hash', { deep: true, immediate: true })
  onRouteChange(newHash, oldHash) {
    if ((this.backClose === true || this.backClose === '') && oldHash === '#dialog') {
      this.visible = false;
    }
  }

  @Watch('value')
  onVisibleChange(newValue, oldValue) {
    if (newValue !== oldValue) {
      const value = newValue || false
      if (this.backClose === true || this.backClose === '') {
        if (value) {
          if (this.$route.hash !== '#dialog') {
            this.$router.push('#dialog');
          }
        } else if (this.$route.hash === '#dialog') {
          this.$router.back();
        }
      }
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }

  get fullScreen() {
    return this.$vuetify.breakpoint.smAndDown && !this.noFull
  }

  get contentClass() {
    return this.height && this.height !== '' ? 'elevation-0 rounded-0 overflow-hidden' : 'elevation-0 rounded-0 no-scroll-x';
  }

  get dialogClass(): string {
    if (this.fullScreen) {
      return ''
    } else {
      return this.flat
        ? 'elevation-0 rounded-' + settings.company.companyUISettings.dialog.rounded
        : 'elevation-' + settings.company.companyUISettings.dialog.elevation + ' rounded-' + settings.company.companyUISettings.dialog.rounded
    }
  }

  get cardClass(): string {
    if (this.fullScreen) {
      return 'rounded-0'
    } else {
      return 'rounded-' + settings.company.companyUISettings.dialog.rounded
    }
  }

  get cardStyle(): string {
    let style = ''

    if (this.fullScreen) {
      style += 'height: 100%; '
    } else if (this.height) {
      style += 'max-height: 85vh; height: ' + this.height + '; ';
    } else if (settings.company.companyUISettings.dialog.height) {
      style += 'max-height: 85vh; height: ' + settings.company.companyUISettings.dialog.rounded + '; ';
    }

    if (this.fullScreen) {
      style += 'overflow-y: auto; overflow-x: clip; '
    }

    return style;
  }

  get dialogWidth() {
    return this.width || settings.company.companyUISettings.dialog.width
  }

  get screenSize(): string {
    if (this.$vuetify.breakpoint.xsOnly) {
      return 'xs'
    } else if (this.$vuetify.breakpoint.smOnly) {
      return 'sm'
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return 'md'
    } else if (this.$vuetify.breakpoint.lgOnly) {
      return 'lg'
    } else {
      return 'xl'
    }
  }

  get showCloseInHeader(): boolean {
    return !this.persistent && settings.company.companyUISettings.dialog.closeInHead.includes(this.screenSize);
  }

  get showCloseInFooter(): boolean {
    return !this.noClose && settings.company.companyUISettings.dialog.closeInFooter.includes(this.screenSize);
  }

  get isVertical(): boolean {
    return settings.company.companyUISettings.dialog.vertical
  }

  get btnColSize(): number {
    const cnt = this.showCloseInFooter ? this.buttons.length + 1 : this.buttons.length;

    if (this.isVertical || cnt === 0) {
      return 12
    } else {
      let size = 12 / cnt
      return size < 1 ? 1 : Math.ceil(size);
    }
  }

  get buttons() {
    return this.$slots.buttons ? this.$slots.buttons.filter(b => b.componentOptions && b.componentOptions.tag === 'wml-button') : [];
  }
}
