
import settings from '@/plugins/settings';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class BaseEntity extends Vue {
  @Prop(Boolean) readonly fullHeight: boolean | undefined;
  @Prop(Boolean) readonly fullWidth: boolean | undefined;
  @Prop() readonly color: any;
  @Prop() readonly print: any;
  @Prop() readonly extraClass: any;

  get viewColor() {
    if (this.print === '' || this.print === true) {
      return 'white'
    } else {
      return this.color && this.color !== '' ? this.color : 'view'
    }
  }

  get viewClass(): string {
    let cls = '';
    cls += 'rounded-' + settings.company.companyUISettings.view.rounded + ' '
    cls += 'elevation-' + settings.company.companyUISettings.view.elevation + ' '

    if (settings.company.companyUISettings.view.outlined) {
      cls += 'border: 1px solid ' + (this.$vuetify.theme.currentTheme['disabled'] || '#777777') + ' '
    }

    cls += 'mx-auto '
    return cls;
  }

  get viewStyle(): string {
    let style = '';

    if (this.fullWidth) {
      style += 'width: 100%; '
    } else {
      const w = Number(settings.company.companyUISettings.view.width)
      style += 'width: ' + w + 'px; '
    }
    style += 'max-width: 100%; '

    if (this.fullHeight) {
      style += 'height: 100%; '
    }

    return style;
  }

  get outboundStyle(): string {
    let style = '';
    style += 'width: 100%; '
    style += 'height: 100%; '
    style += 'padding: ' + settings.company.companyUISettings.view.margin + 'px; '

    return style;
  }

  get containerClass(): string {
    return this.extraClass && this.extraClass.trim() !== '' ? this.extraClass : 'pa-0'
  }
}
