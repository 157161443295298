
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import $ph from '@/plugins/phoenix'
import { GlossaryItemCode, IGlossaryItem } from '@/plugins/phoenix/glossaries';
import { FilterFieldType, IFilterField } from '../types';
import settings from '@/plugins/settings';

@Component
export default class BaseListView extends Vue {
  @Prop() readonly caption: any;
  @Prop() readonly hideFilter: any;
  @Prop() readonly hideAppend: any;
  @Prop() readonly showExport: any;
  @Prop() readonly showMenu: any;
  @Prop() readonly accessFind: any;
  @Prop() readonly accessAppend: any;
  @Prop() readonly accessEdit: any;
  @Prop() readonly accessMenu: any;
  @Prop() readonly accessRemove: any;
  @Prop() readonly filters: any;
  @Prop() readonly value: any;
  @Prop() readonly loading: any;

  filter: any = {};
  listmap: Record<string, Record<GlossaryItemCode, IGlossaryItem<GlossaryItemCode>>> = {};

  multiVisible: boolean = false;
  multiField: IFilterField | null = null;
  filterMenu: boolean = false;
  selectedFilters: Array<IFilterField> = [];

  get selectedTags() {
    this.selectedFilters.forEach(f => {
      f.code = f.name
      f.label = $ph.i18n('filter.' + f.name) + ' = ' + f.chipCaption
      f.color = 'primary lighten-2'
    })

    return this.selectedFilters;
  }

  get viewClass(): string {
    let cls = '';
    cls += 'rounded-' + settings.company.companyUISettings.view.rounded + ' '
    cls += 'elevation-' + settings.company.companyUISettings.view.elevation + ' '
    cls += 'my-' + settings.company.companyUISettings.view.margin + ' '
    cls += 'mx-auto '
    cls += 'pb-16 '
    return cls;
  }

  get viewStyle(): string {
    let style = '';
    style += 'width: calc(100% - ' + (4 * settings.company.companyUISettings.view.margin) + 'px); '
    style += 'max-width: 1500px; '
    return style;
  }

  get isFilterHide(): boolean {
    return this.hideFilter || this.hideFilter === '';
  }

  get isAppendHide(): boolean {
    return this.hideAppend || this.hideAppend === '';
  }

  get isMenuVisible(): boolean {
    return this.showMenu || this.showMenu === '';
  }

  get isExportVisible(): boolean {
    return this.showExport || this.showExport === '';
  }

  get filterFields(): Array<IFilterField> {
    return this.filters;
  }

  get isAccesibleFind() {
    return !this.accessFind || $ph.access(this.accessFind)
  }

  get isAccesibleMenu() {
    return !this.accessMenu || $ph.access(this.accessMenu)
  }

  get isAccesibleAppend() {
    return !this.accessAppend || $ph.access(this.accessAppend)
  }

  get isAccesibleEdit() {
    return !this.accessEdit || $ph.access(this.accessEdit)
  }

  get isAccesibleRemove() {
    return !this.accessRemove || $ph.access(this.accessRemove)
  }

  getSelectedChips(field: IFilterField) {
    return this.filter[field.name] ? field.items.filter(e => this.filter[field.name].some((f: any) => f === e.code)) : [];
  }

  setSelectedChips(field: IFilterField, event: Array<IGlossaryItem<any>>) {
    field.currentValue = [];
    event.forEach(i => this.addMulti(field, i))
  }

  getChipCaption(value: any, field: IFilterField) {
    if (field.type === FilterFieldType.DateRange) {
      return !value ? '[]' : '[' + $ph.format(value.from, { ds: 'DATE' }) + ', ' + (value.till ? $ph.format(value.till, { ds: 'DATE' }) : '∞') + ']';
    } else if (field.type === FilterFieldType.NumberRange) {
      return !value ? '[]' : '[' + (value.from || '0') + ', ' + (value.till || '∞') + ']';
    } else if (field.type === FilterFieldType.Switch) {
      return value ? $ph.i18n('system.Yes') : $ph.i18n('system.No');
    } else if (field.type === FilterFieldType.List) {
      const selected = !value ? null : this.listmap[field.name][value];
      return !selected ? value : $ph.i18n(selected.label);
    } else if (field.type === FilterFieldType.MultiList) {
      let result = '[';
      if (value) {
        value.forEach((el: any) => {
          const selected = !el ? null : this.listmap[field.name][el];
          const label = !selected ? el : $ph.i18n(selected.label);
          result += result.length > 1 ? ', ' + label : label;
        });
      }
      result += ']';
      return result;
    } else {
      return value;
    }
  }

  isDefault(value: any, field: IFilterField): boolean {
    let res = false;
    if (field.type === FilterFieldType.DateRange || field.type === FilterFieldType.NumberRange) {
      res = !value || (value.from === field.defaultValue.from && value.till === field.defaultValue.till);
    } else if (field.type === FilterFieldType.MultiList) {
      res = !value || value.length === 0;
    } else {
      res = value === field.defaultValue;
    }
    return res;
  }

  @Watch('value', { immediate: true })
  onFilterChanged(value: any) {
    this.filter = Object.assign({}, value);
    this.refreshFilter();
  }

  refreshFilter() {
    let result: Array<IFilterField> = [];
    this.listmap = {};

    Object.keys(this.filter).forEach(key => {
      this.filterFields.forEach(field => {
        if (field.items.length > 0) {
          const index: Record<GlossaryItemCode, IGlossaryItem<GlossaryItemCode>> = {};
          field.items.forEach(item => {
            index[item.code] = item;
          });
          this.listmap[field.name] = index;
        }

        if (field.name === key && this.filter[key] && !this.isDefault(this.filter[key], field)) {
          let item = Object.assign({}, field)
          item.currentValue = this.filter[key];
          item.chipCaption = this.getChipCaption(this.filter[key], field);
          result.push(item)
        }
      })
    });
    this.selectedFilters = result;
  }

  applyFilter() {
    this.filterMenu = false;
    this.filter.offset = 0;
    this.$emit('input', Object.assign({}, this.filter))
  }

  clearFilter() {
    this.filterMenu = false;
    Object.keys(this.filter).forEach(key => {
      this.filterFields.forEach(filter => {
        if (filter.name === key) {
          this.filter[key] = filter.defaultValue;
        }
      })
    });
    this.filter.offset = 0;
    this.$emit('input', Object.assign({}, this.filter))
  }

  removeFilter(tag: IFilterField) {
    this.filter[tag.name] = tag.defaultValue;
    this.filter.offset = 0;
    this.$emit('input', Object.assign({}, this.filter))
  }

  openMulti(field: IFilterField) {
    this.multiField = field;
    this.multiVisible = true;
  }

  addMulti(field: IFilterField, chip: IGlossaryItem<any>) {
    if (!this.filter[field.name]) {
      field.currentValue = [];
    }
    field.currentValue.push(chip.code);
    Vue.set(this.filter, field.name, field.currentValue);
  }

  removeMulti(field: IFilterField, chip: IGlossaryItem<any>) {
    if (this.filter[field.name]) {
      const items: Array<any> = field.currentValue;
      const i = items.findIndex(e => e === chip.code);

      if (i !== -1) {
        items.splice(i, 1);
      }

      Vue.set(this.filter, field.name, items.length === 0 ? null : items);
    }
  }

  find(clear: boolean) {
    if (clear) {
      this.filter.defaultSearch = null;
    }
    this.filter.offset = 0;
    this.$emit('input', Object.assign({}, this.filter))
  }

  exportCSV() {
    this.filter.export = true;
    this.$emit('input', Object.assign({}, this.filter))
  }

  append() {
    this.$emit('append')
  }

  menu() {
    this.$emit('menu')
  }
}
